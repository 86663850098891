import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Container, MenuItem, Select } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import ArquivoService from '../services/ArquivoService';
import Service from '../services/Service';
import AlertDialog from './AlertDialog';
import Dropzone from './Dropzone';
import './EnsalementoForm.css'

const MAX_INSTANCIA = 35;

export default function EnsalementoForm() {

    const [wait, setWait] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [mensagem, setMensagem] = React.useState();
    const [anexo, setAnexo] = React.useState();
    const [selectedFileUpload, setSelectedFileUpload] = React.useState();
    const [instancias, setInstancias] = React.useState();
    const [ifEnviar, setIfEnviar] = React.useState(false);
    const [ifCSV, setIfCSV] = React.useState(false);
    const [erroInstancia, setErroInstancia] = React.useState();

    const navigate = useNavigate();

    React.useEffect(() => {


        if (selectedFileUpload) {
            if (selectedFileUpload.type !== "text/csv") {
                
                setIfCSV(true);
            
            }
                
            
            var teste = selectedFileUpload.type;
            console.log(teste)
            console.log("anexo", anexo)
            let file = document.getElementById("file");
            let dados = new FormData();
            setWait(true);
            dados.append('file', selectedFileUpload);
            ArquivoService.upload(dados).then((result) => {
                setWait(false);
                var nm = selectedFileUpload.name;

                let anexo = {
                    nome: nm,
                    uuid: result.uuid
                };
                console.log(result)
                if( result.status === 500){
                    setMensagem("Não pode transferir o arquivo! "+result.error)
                }else{
                    setAnexo(anexo);
                    setSelectedFileUpload();
                }
            }).catch((e) => {
                setWait(false);
                setMensagem({ tipo: 'error', mensagem: e.message });
            });

        }

    }, [selectedFileUpload])

    const upload = () => {
        let file = document.getElementById("file");
        let dados = new FormData();
        setWait(true);
        dados.append('file', file.files[0]);
        ArquivoService.upload(dados).then((result) => {
            setWait(false);
            var nm = file.value.split(/(\\|\/)/g).pop();

            let anexo = {
                nome: nm,
                uuid: result.uuid
            };
            console.log(anexo)
            setAnexo(anexo);
            file.value = null;
        }).catch((e) => {
            setWait(false);
            setMensagem({ tipo: 'error', mensagem: e.message });
        });
    }
    const addAnexo = () => {

        let file = document.getElementById("file");
        console.log("aaaa" + file)
        file.click();
    }

    const handleClick = () => {

        console.log(anexo)
        if (anexo && instancias) {
            console.log("deu certo")
            const dado = {      
                file: anexo,
                instancias: instancias
            }
            console.log(dado);
            setOpen(true);
            Service.POST('/api/processo/prepara', dado)
                .then(r => {
                    setInstancias()
                    setSelectedFileUpload();
                    // setOpen(false);
                    console.log(r);
                });

        } else {

            setIfEnviar(true)
            console.log("nao certo")
        }

    }

    const atualizaPagina = () => {
        window.location.reload();
        navigate("/validadorEnsalemento", { state: { key: "value" } });
    }

    const proximaPagina = () => {
        // window.location.reload();
        navigate("/localProva");
    }

    function desabilitar() {
        if (anexo && instancias) {
            console.log(anexo)
            console.log(instancias)
            return true;
        }
    }
    const handleInstancia=(e)=>{
        console.log( e.target.value);
        if( e.target.value > MAX_INSTANCIA){
            console.log(".")
            setInstancias( MAX_INSTANCIA );
            setErroInstancia( "Não pode ultrapassar "+ MAX_INSTANCIA+" instancias.")
            return;
        }
        setErroInstancia()
        setInstancias(e.target.value)
    }

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={wait}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
            {mensagem && <Alert onClose={() => {setMensagem()}} severity="error">{mensagem} <br/>Confirme o tamanho do arquivo.</Alert> }


                <Dropzone onFileUploaded={setSelectedFileUpload} /> 
                <br />
                <Stack
                    direction="row-reverse"
                    spacing={2}

                >

                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Button variant="contained" endIcon={<SendIcon />} onClick={handleClick}>
                            Enviar
                        </Button>
                    </Box>
                    <Box
                        component="form"
                        sx={{

                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField 
                            error={(erroInstancia && erroInstancia.length> 1)}
                            helperText={erroInstancia && erroInstancia}
                            id="standard-basic" 
                            label="Quantidade de Processamento?" 
                            type="number" value={instancias || ''} 
                            variant="standard" onChange={handleInstancia} 
                        />

                    
                    </Box>







                    <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/csv" />
                </Stack>

                <Box>
                    <div className='how-to'>
                        <ol>
                            <h2 className='title-how-to'> Como utilizar:</h2>
                            <li>
                                <h1>1</h1> <div>Clique no botão <strong>Selecionar um arquivo</strong> ou arraste e solte arquivos na área de upload. </div>

                            </li>
                            <li>
                                <h1>2</h1> <div>Selecione o arquivo de ensalamento que você deseja processar.</div>

                            </li>
                            <li>
                                <h1>3</h1> <div>Informe a quantidade de processamentos em paralelo que deve ser realizado.</div>

                            </li>
                            <li>
                                <h1>4 </h1> <div>Clique em <strong>Enviar</strong>. Após o upload, o sistema ira processar o arquivo.</div>

                            </li>
                        </ol>
                    </div>


                </Box>
            </Container>
            <AlertDialog open={open} setOpen={proximaPagina} title={"Processo em execução"} btnLabel={"OK"} actionOnClick={proximaPagina} >
                <Typography gutterBottom> O tempo para processar o conteudo depende do tamanho do arquivo csv e da quantidade de instancias que será executada,
                    isso pode demorar de minutos e ate horas, o processomento pode ser acompanhado na tela
                    <a href='/listaArquivos'> Listagem de arquivo</a> ou nos registros processados <a href='/localProva'> Tabale Local de prova</a>.
                    O tempo para processar o conteudo depende do tamanho do arquivo csv e da quantidade de instancias que será executada,
                    isso pode demorar de minutos e ate horas, o processomento pode ser acompanhado na tela
                    <a href='/listaArquivos'> Listagem de arquivo</a> ou nos registros processados <a href='/localProva'> Tabale Local de prova</a>.
                </Typography>
            </AlertDialog>

            <AlertDialog open={ifEnviar} setOpen={setIfEnviar} btnLabel={"OK"} actionOnClick={atualizaPagina} >
                <Typography gutterBottom>
                    Informe a quantidade de instancias e um arquivo .CSV
                </Typography>
            </AlertDialog>

            <AlertDialog open={ifCSV} setOpen={setIfCSV} btnLabel={"OK"} actionOnClick={atualizaPagina} >

                Arquivo inválido!
                <br />
                Informe um Arquivo .CSV

            </AlertDialog>
        </div>
    );
}
