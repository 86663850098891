import React from "react";

import { Alert, AlertTitle, Box, Button, Container, CssBaseline, Grid, Stack, TextField, ThemeProvider, createTheme, } from "@mui/material";
import { Link } from "react-router-dom";
import Service from "../services/Service";


export const ACCESS_TOKEN = 'accessToken';

export default function TelaLogin() {
    const [user, setUser] = React.useState({
        login: '',
        password: ''
    });
    const defaultTheme = createTheme();

    const [open, setOpen] = React.useState(false)
    const [mensagemAlerta, setMensagemAlerta] = React.useState({
        tipo: 'info',
        duracao: 5000,
        mensagem: "Mensagem não anexada"
    })

    const handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.id;
        user[name] = value;

        setUser(user);
    }


    function logar() {
        console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaa", user)
        Service.POST("/api/user/login",user)
            .then(r => {
                console.log(r);
                localStorage.setItem(ACCESS_TOKEN, r.accessToken);
                localStorage.setItem("perfil", r.perfil);
                window.location.href = "/";
                console.log(localStorage.accessToken)
            }).catch(error => {
                console.log("ERRO: ", error);
                setMensagem({   
                    tipo: "error",
                    message: "Problema ao verificar credenciais do usuário. Efetue novo login."
                })
            });

    }

    function setMensagem(alerta) {
        console.log("Mensagem: ", alerta);
        let a;
        if (typeof alerta === 'object') {
            console.log("Mensagem(object): ", alerta);
            a = {
                tipo: alerta && alerta.tipo ? alerta.tipo : "info",
                duracao: alerta && alerta.duracao ? alerta.duracao : 5000,
                mensagem: alerta.message
            }

        } else {
            a = {
                tipo: 'info',
                duracao: 10000,
                mensagem: alerta
            }
        }
        setOpen(true);
        setMensagemAlerta(a);
        if (a && !!a.duracao && a.duracao > 0) {
            window.setTimeout(() => {
                setOpen(false);
            }, a.duracao)
        }
    }


    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">

                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '80vh'
                    }}
                >
                    {open &&
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity={mensagemAlerta.tipo}>
                                {mensagemAlerta.titilo && <AlertTitle>{mensagemAlerta.titilo}</AlertTitle>}
                                {mensagemAlerta.mensagem}
                            </Alert>
                        </Stack>
                    }

                    {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
                    <img src="teste.png" width={400} style={{ marginTop: '40px', background: 'blue', borderRadius: 5 }} />
                    {/* </Avatar> */}
                    <div style={{ marginTop: '10px' }} />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="login"
                        label="Login"
                        name="login"
                        autoComplete="login"
                        autoFocus
                        onChange={handleInputChange}


                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleInputChange}


                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => logar()}
                    >
                        Entrar
                    </Button>

                </Box>
            </Container>
        </ThemeProvider>
    )
}

