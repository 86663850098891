import { Box, Button, Checkbox, Divider, Fade, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Modal, Paper, Popper, Slider, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, Marker, Polyline, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster'
import L from "leaflet";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import FlagIcon from '@mui/icons-material/Flag';
import Service from "../services/Service";
import roomIconMapaVermelho from '../components/Imagens/roomIconMapaVermelho.png';
import roomIconMapaAzul from '../components/Imagens/roomIconMapaAzul.png';
import SearchIcon from '@mui/icons-material/Search'
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { CheckBox } from "@mui/icons-material";
import StraightenIcon from '@mui/icons-material/Straighten';

export default function TelaMapaInscricoes() {
    const [listDestino, setListDestino] = useState([]);
    const [ufExistente, setUfExistente] = useState([]);
    const [menuLateral, setMenuLateral] = useState(false)
    const [listOrigem, setListOrigem] = useState([]);
    const [listaPontoaPonto, setListaPontoaPonto] = useState([]);
    const [listaPontoaPontoFiltrada, setListaPontoaPontoFiltrada] = useState([]);
    const [selectedUfs, setSelectedUfs] = useState([]);
    const [agrupar, setAgrupar] = useState(true)
    const [listaConcatenada, setListaConcatenada] = useState([]);
    const [listaConcatenadaFiltrada, setListaConcatenadaFiltrada] = useState([]);
    const [localProva, setLocalProva] = useState(false)
    const [localInscricao, setLocalInscricao] = useState(false)
    const [openRegiao, setOpenRegiao] = React.useState(false);
    const [openMunicipio, setOpenMunicipio] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [cidades, setCidades] = useState([]);
    const [selectedCidades, setSelectedCidades] = useState([]);
    const [openDistancia, setOpenDistancia] = useState(false);
    const [distancia, setDistancia] = useState(0);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const inputEl = useRef(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [placement, setPlacement] = React.useState();

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
        setOpenRegiao(false);
        setOpenMunicipio(false);
        setOpenDistancia(false);
    };
    const handleClickRegiao = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenRegiao(!openRegiao);
        setOpen(false);
        setOpenMunicipio(false);
        setOpenDistancia(false);
    };
    const handleClickMunicipio = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenMunicipio(!openMunicipio);
        setOpen(false);
        setOpenRegiao(false);
        setOpenDistancia(false);
    };
    const handleClickDistancia = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenMunicipio(false);
        setOpen(false);
        setOpenRegiao(false);
        setOpenDistancia(!openDistancia);
    };

    const handleChange = (_, newValue) => {
        setDistancia(newValue);
        console.log(newValue)
        let arrListaSele = [];
        for(let x in listaPontoaPonto){
            if(listaPontoaPonto[x].distancia <= newValue){
                arrListaSele.push(listaPontoaPonto[x])
            }
        }
        setListaPontoaPontoFiltrada(arrListaSele)
    };

    useEffect(() => {

        Service.GET('/api/localProva/listAll')
            .then(r => {
                let arrUfExiste = [];
                for (let x in r) {
                    const existeDestino = listDestino.some(coord => coord.coordenada.latitude === r[x]?.coorD.latitude && coord.coordenada.longitude === r[x]?.coorD.longitude)
                    const existeOrigem = listOrigem.some(coord => coord.coordenada.latitude === r[x]?.coorO.latitude && coord.coordenada.longitude === r[x]?.coorO.longitude)
                    const exiteUf = arrUfExiste.some(item => item.uf === r[x]?.sg_uf)
                    const exitePontoPonto = listaPontoaPonto.some(item =>
                        item.linha[0][0] === r[x]?.coorD.latitude &&
                        item.linha[0][1] === r[x]?.coorD.longitude &&
                        item.linha[1][0] === r[x]?.coorO.latitude &&
                        item.linha[1][1] === r[x]?.coorO.longitude)
                    if (!existeDestino) listDestino.push({
                        coordenada: r[x]?.coorD,
                        local: r[x]?.sg_uf,
                        co_municipio: r[x]?.co_municipio.toString()
                    });

                    if (!existeOrigem) listOrigem.push({
                        coordenada: r[x]?.coorO,
                        local: r[x]?.sg_uf,
                        co_municipio: r[x]?.co_municipio.toString()
                    })

                    if (!exitePontoPonto) {
                        let coordenada = [
                            [r[x]?.coorD.latitude, r[x]?.coorD.longitude],
                                [r[x]?.coorO.latitude, r[x]?.coorO.longitude]
                        ]
                        
                        listaPontoaPonto.push({
                            linha: coordenada,
                            distancia: calculateDistance(coordenada)
                        })
                    }

                    if (!exiteUf) arrUfExiste.push({
                        uf: r[x]?.sg_uf
                    })


                }
                let arrNovo = [];
                for (let x in arrUfExiste) {
                    arrNovo.push(arrUfExiste[x].uf)
                }
                setSelectedUfs(arrNovo);
                setUfExistente(arrUfExiste)
                concatenarList();

            });
    }, [])



    useEffect(() => {
        let arr = []
        for (let x in listaConcatenada) {
            const item = listaConcatenada[x]
            if (localInscricao && item?.tipo === 'origem') {
                arr.push(item)

                console.log(selectedUfs)

            };

            if (localProva && item?.tipo === 'destino') {
                arr.push(item)


            };




        }
        setListaConcatenadaFiltrada(arr)
        handleClose()


    }, [localProva, localInscricao])


    useEffect(() => {
        let arr = []
        for (let x in listaConcatenada) {
            const item = listaConcatenada[x]
            //console.log(item)
            for (let x in selectedUfs) {
                const ufSelecionada = selectedUfs[x]
                //console.log("aquiii",selectedUfs && item?.local === ufSelecionada)
                if (selectedUfs && item?.local === ufSelecionada) {
                    arr.push(item)

                    // console.log(item)

                };

            }


        }
        setListaConcatenadaFiltrada(arr)

        buscaCidades(selectedUfs);

    }, [selectedUfs])

    useEffect(() => {
        let arr = []
        for (let x in listaConcatenada) {
            const item = listaConcatenada[x]
            // console.log("item >>>>>>. ",item)
            // console.log("aquiii",selectedCidades , item)
            for (let x in selectedCidades) {
                const ufSelecionada = selectedCidades[x]

                if (selectedCidades && item?.co_municipio === ufSelecionada) {
                    arr.push(item)

                    // console.log(item)

                };

            }


        }
        // console.log("arr >>>>>>. ",arr)
        setListaConcatenadaFiltrada(arr)

    }, [selectedCidades])

    const concatenarList = () => {

        setListaConcatenada([
            ...listOrigem.map(item => ({ ...item, tipo: 'origem' })),
            ...listDestino.map(item => ({ ...item, tipo: 'destino' }))
        ])


        setListaConcatenadaFiltrada([
            ...listOrigem.map(item => ({ ...item, tipo: 'origem' })),
            ...listDestino.map(item => ({ ...item, tipo: 'destino' }))
        ])

    }

    function buscarIcone(d) {
        let icone = roomIconMapaVermelho;
        if (d == 'origem') {
            icone = roomIconMapaVermelho;
        } else if (d == 'destino') {
            icone = roomIconMapaAzul;
        }

        let resp = L.icon({
            iconUrl: icone,
            iconAnchor: [14, 42],
            popupAnchor: [-1.5, -42]
        });
        return resp;
    }




    const handleSelectAll = (event) => {
        if (event.target.checked) { // todos selecionados
            let arrNovo = [];
            for (let x in ufExistente) {
                arrNovo.push(ufExistente[x].uf)
            }
            setSelectedUfs(arrNovo);

        } else {
            setSelectedUfs([]);
        }
    };

    const handleSelectUf = (event) => {
        const { value } = event.target;
        if (selectedUfs.includes(value)) {
            setSelectedUfs(selectedUfs.filter((uf) => uf !== value));
        } else {
            setSelectedUfs([...selectedUfs, value]);
        }
    };

    function buscaCidades(ufs) {
        if (!ufs || ufs.length === 0) return;
        Service.GET('/api/localProva/findByUF?uf=' + ufs)
            .then(r => {
                let arr = [];
                let arrSeleci = [];
                for (let x in r) {
                    arr.push({
                        nome: r[x].nome,
                        id: r[x].id.toString()
                    })
                    arrSeleci.push(r[x].id.toString());
                }
                setCidades(arr);
                setSelectedCidades(arrSeleci);
            });
    }

    const handleSelectAllCidades = (event) => {
        if (event.target.checked) {
            let arr = [];
            for (let x in cidades) {
                arr.push('' + cidades[x].id);
            }
            setSelectedCidades(arr);
        } else {
            setSelectedCidades([]);
        }
    }

    const handleSelectCidade = (event) => {
        const { value } = event.target;
        const item = selectedCidades.find((c) => {
            return c == value;

        });

        if (item) {
            setSelectedCidades(selectedCidades.filter(c => c != value));
        } else {
            setSelectedCidades([...selectedCidades, value]);
        }
        // if (selectedCidades.includes(value)) {
        //     setSelectedCidades(selectedCidades.filter((c) => c.id !== value));
        // } else {
        //     setSelectedCidades([...selectedCidades, value]);
        // }
    }

    function calculateDistance(coord) {
        const R = 6371; // Raio da Terra em Km
        const dLat = (coord[1][0] - coord[0][0]) * Math.PI / 180; // Diferença de latitude em radianos
        const dLon = (coord[1][1] - coord[0][1]) * Math.PI / 180; // Diferença de longitude em radianos
        const a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(coord[0][0] * Math.PI / 180) * Math.cos(coord[1][0] * Math.PI / 180) * 
          Math.sin(dLon/2) * Math.sin(dLon/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        const distance = R * c; // Distância em quilômetros
        // console.log("dis:",distance ," ->", Math.ceil(distance));
        return Math.ceil(distance);
      }

    const list = () => (
        <Box
            sx={{ '& button': { m: 1 } }}
            role="presentation"
        >
            <FormGroup style={{ marginLeft: '10px' }} >
                <FormLabel style={{ marginLeft: '17px', color: 'white' }} component="legend">Local prova</FormLabel>
                <Button variant="contained" onClick={() => setLocalProva(!localProva)} sx={{
                    backgroundColor: localProva ? '#FFD326' : '#F4F6F8',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: '#FFD326',
                    }
                }} >Local prova</Button>
                <Button variant="contained" onClick={() => setLocalInscricao(!localInscricao)} sx={{
                    backgroundColor: localInscricao ? '#3232CD' : '#F4F6F8',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: '#3232CD',
                    }
                }} >Inscrições</Button>
            </FormGroup>
        </Box>
    );




    return (
        <React.Fragment>
            <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
                {menuLateral &&
                    <Grid item xs={1} justifyContent="center" alignItems="center" sx={{ backgroundColor: '#1B3058' }}>
                        {list()}
                    </Grid>
                }
                <Grid item xs={menuLateral ? 11 : 12} sx={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box
                        sx={{
                            width: 1500,
                            height: 750,
                            marginBottom: 16,
                            border: "3px solid black",
                            position: "relative",
                        }}
                    >
                        <MapContainer
                            center={[-15.7891, -47.8850]}
                            doubleClickZoom={false}
                            id="mapId"
                            zoom={4}
                            style={{ width: "100%", height: "100%" }} // O mapa ocupa todo o Box
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <MarkerClusterGroup chunkedLoading>
                                {listaConcatenadaFiltrada && listaConcatenadaFiltrada.map((coord, index) => (
                                    <Marker key={index} position={[coord?.coordenada.latitude, coord?.coordenada.longitude]} icon={buscarIcone(coord?.tipo)} />
                                ))}
                            </MarkerClusterGroup>

                            {listaPontoaPontoFiltrada && listaPontoaPontoFiltrada.map((coord, index) => (
                                <Polyline key={index + "_xpto"} positions={coord.linha} color={'#6FA1EC'} />
                            ))}

                            <IconButton aria-label="filter" onClick={handleClick('right')} sx={{

                                position: 'absolute',
                                top: 76,
                                left: -2,
                                zIndex: 1000,
                                backgroundColor: '#fff',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}>
                                {menuLateral ? <KeyboardDoubleArrowLeftIcon sx={{ color: '#1B3058' }} /> : <KeyboardDoubleArrowRightIcon sx={{ color: '#1B3058' }} />}
                            </IconButton>
                            <IconButton aria-label="filter" onClick={handleClickRegiao} sx={{
                                position: 'absolute',
                                top: 126,
                                left: -2,
                                zIndex: 1000,
                                backgroundColor: '#fff',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}>
                                <FlagIcon sx={{ color: '#1B3058' }} />
                            </IconButton>
                            <IconButton aria-label="filter" onClick={handleClickMunicipio} sx={{
                                position: 'absolute',
                                top: 176,
                                left: -2,
                                zIndex: 1000,
                                backgroundColor: '#fff',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}>
                                <LocationCityIcon sx={{ color: '#1B3058' }} />
                            </IconButton>
                            <IconButton aria-label="filter" onClick={handleClickDistancia} sx={{
                                position: 'absolute',
                                top: 226,
                                left: -2,
                                zIndex: 1000,
                                backgroundColor: '#fff',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}>
                                <StraightenIcon sx={{ color: '#1B3058' }} />
                            </IconButton>
                            <Popper
                                sx={{ zIndex: 1200, borderRadius: 3 }}
                                open={open}
                                anchorEl={anchorEl}
                                placement={placement}
                                transition
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} >
                                        <Paper sx={{ borderRadius: 3, marginLeft: 1 }}>
                                            {/* <Typography sx={{ p: 2 }}>The content of the Popper.</Typography> */}
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                                                <Button variant="contained" onClick={() => setLocalProva(!localProva)} sx={{
                                                    backgroundColor: localProva ? '#3B55CE' : '#F4F6F8',
                                                    color: 'black',
                                                    '&:hover': {
                                                        backgroundColor: '#3B55CE',
                                                    }
                                                }} >Local prova</Button>
                                                <Button variant="contained" onClick={() => setLocalInscricao(!localInscricao)} sx={{
                                                    backgroundColor: localInscricao ? '#CE3A32' : '#F4F6F8',
                                                    color: 'black',
                                                    '&:hover': {
                                                        backgroundColor: '#CE3A32',
                                                    }
                                                }} >Inscrições</Button>

                                            </Box>
                                        </Paper>
                                    </Fade>
                                )}


                            </Popper>
                            <Popper
                                sx={{ zIndex: 1200, borderRadius: 3 }}
                                open={openRegiao}
                                anchorEl={anchorEl}
                                placement="right"
                                transition
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} >
                                        <Paper sx={{ borderRadius: 3, marginLeft: 1 }}>
                                            {/* <Typography sx={{ p: 2 }}>The content of the Popper.</Typography> */}
                                            <Box sx={{ overflowY: "auto", p: 2, height: 240 }}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedUfs.length === ufExistente.length}
                                                                onChange={handleSelectAll}
                                                            />
                                                        }
                                                        label="Todos"
                                                    />
                                                    {ufExistente.map((dado, key) => (
                                                        <FormControlLabel
                                                            sx={{ marginLeft: 2 }}
                                                            key={key}
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedUfs.includes(dado?.uf)}
                                                                    onChange={handleSelectUf}
                                                                    value={dado?.uf}
                                                                />
                                                            }
                                                            label={dado?.uf}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </Box>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                            <Popper
                                sx={{ zIndex: 1200, borderRadius: 3 }}
                                open={openMunicipio}
                                anchorEl={anchorEl}
                                placement="right"
                                transition
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} >
                                        <Paper sx={{ borderRadius: 3, marginLeft: 1 }}>
                                            {/* <Typography sx={{ p: 2 }}>The content of the Popper.</Typography> */}
                                            <Box sx={{ overflowY: "auto", p: 2, height: 240 }}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={cidades.length > 0 && selectedCidades.length === cidades.length}
                                                                onChange={handleSelectAllCidades}
                                                            />
                                                        }
                                                        label="Todos"
                                                    />
                                                    {cidades.map((dado, key) => (
                                                        <FormControlLabel
                                                            sx={{ marginLeft: 2 }}
                                                            key={key}
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedCidades.includes(dado?.id)}
                                                                    onChange={handleSelectCidade}
                                                                    value={dado?.id}
                                                                />
                                                            }
                                                            label={dado?.nome}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </Box>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                            <Popper
                                sx={{ zIndex: 1200, borderRadius: 3 }}
                                open={openDistancia}
                                anchorEl={anchorEl}
                                placement="bottom"
                                transition
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} >
                                        <Paper sx={{ borderRadius: 3, marginLeft: 1 }}>
                                            {/* <Typography sx={{ p: 2 }}>The content of the Popper.</Typography> */}
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, height: 250, marginLeft: 3 }}>
                                                <Slider
                                                    sx={{
                                                        '& input[type="range"]': {
                                                            WebkitAppearance: 'slider-vertical',
                                                        }
                                                    }}
                                                    step={5}
                                                    value={distancia}
                                                    valueLabelDisplay="auto"
                                                    
                                                    min={0}
                                                    max={200}
                                                    onChange={handleChange}
                                                    orientation="vertical"
                                                // onKeyDown={preventHorizontalKeyboardNavigation}
                                                />
                                            </Box>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>

                        </MapContainer>
                    </Box>
                </Grid>
            </Grid>


        </React.Fragment >
    );



}