import * as React from 'react';
import ReactDOM from 'react-dom/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MapIcon from '@mui/icons-material/Map';

import { Grid, InputBase, TableSortLabel } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Service from '../../services/Service';
import RotaInscricao from '../RotaInscricao';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}



function Resultados() {
    const [prova, setProva] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [filtro, setFiltro] = React.useState();
    const [dadosTabela, setDadosTabela] = React.useState([]);
    // const [carregando, setCarregando] = React.useState(false);
    const [ordem, setOrdem] = React.useState({
        campo: '',
        ordem: 'asc'
    });

    React.useEffect(() => {
        const dado = {
            page: 0,
            rowsPerPage: rowsPerPage,
            filter: filtro,
            ordem: ordem
        }
        buscaLocalProva(dado);
        dadosResultado()
    }, [filtro, rowsPerPage, ordem])


    const handleChangePage = (event, newPage) => {
        const dado = {
            page: newPage,
            rowsPerPage: rowsPerPage,
            filter: filtro,
            ordem: ordem
        }
        buscaLocalProva(dado);

        setPage(newPage);
    };

    const dadosResultado = () => {
        Service.GET('/api/localProva/graficoV2')
            .then(r => {
                setDadosTabela(r.grafico)
                console.log(r.grafico)
                setTotal(r.grafico.lstInscricao.length)
            });
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        const dado = {
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
            filter: filtro,
            ordem: ordem
        }
        buscaLocalProva(dado);
    };

    function buscaLocalProva(dado) {
        // setCarregando(true);

        Service.POST('/api/localProva/list', dado)
            .then(r => {
                console.log(r)
                // setTotal(r.count)
                setProva(r.lista);
            });
    }

    const handleOnFiltrer = (e) => {
        setFiltro(e.target.value);
    }
    const montaLineColor = (row) => {
        if (!row.concluido) return "#76B5E5";
        if (row.rotas == null) return "#DADADA";
        return null;
    }

    const openRotaDialog = (e) => {
        console.log(e)
        const root = ReactDOM.createRoot(
            document.getElementById('rederizarDialog')
        );

        root.render(<RotaInscricao
            inscricao={e.inscricao}
            coorO={e.coorO}
            coorD={e.coorD}
            uf={e.sg_uf}
            municipio_prova={e.municipio_prova}
            open={true}
            distancia={handlerMkM(e.rotas[0].distanceMeters)}
        />
        );
    }

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);
    }

    const handlerMkM = (r) => {
        if (r >= 1000) {
            r = r / 1000 + " Km"
        } else {
            r = r + " M";
        }
        return r;

    }

    return (
        <Container maxWidth="lg">
            <h1 style={{ textAlign: 'center' }}>Resultados</h1>
            {/* <Stack direction="row" spacing={1} style={{ marginBottom: "20px" }}>
                <Grid container >
                    <Grid item xs={12}>
                        <Grid container spacing={2} >
                            <Grid item></Grid>
                            <Grid item>
                                <SearchIcon />
                            </Grid>
                            <Grid item>
                                <InputBase style={{ position: 'absolute', marginRight: '9px' }}
                                    placeholder="Buscar…"
                                    fullWidth
                                    onChange={handleOnFiltrer}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Stack> */}

            <TableContainer component={Paper}>
                <Table aria-label="customized table" size={'small'}>
                    <TableHead style={{ backgroundColor: "#044785" }}>
                        <TableRow >
                            <TableCell sortDirection={false} style={{ color: "white", padding: '12px' }}>
                                <TableSortLabel
                                    active={ordem.campo === 'sg_uf'}
                                    direction={ordem.ordem}
                                    >
                                    UF
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={false} style={{ color: "white" }}>
                                <TableSortLabel
                                    active={ordem.campo === 'co_municipio'}
                                    direction={ordem.ordem}
                                    >
                                    Total Incrições
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={false} style={{ color: "white" }}>
                                <TableSortLabel
                                    active={ordem.campo === 'inscricao'}
                                    direction={ordem.ordem}
                                    >
                                    Maior 30km
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={false} style={{ color: "white" }}>
                                <TableSortLabel
                                    active={ordem.campo === 'inscricao'}
                                    direction={ordem.ordem}
                                    >
                                    Menor 30km
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={false} style={{ color: "white" }}>
                                <TableSortLabel
                                    active={ordem.campo === 'inscricao'}
                                    direction={ordem.ordem}
                                    >
                                    % 30km
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dadosTabela?.lstInscricao?.map((inscricao, index) => (
                            <TableRow key={index}>
                                <TableCell>{inscricao.nome}</TableCell>
                                <TableCell>{inscricao.qtd}</TableCell>

                                {dadosTabela.lstMaior30Km && (
                                    <TableCell>{dadosTabela.lstMaior30Km[index]?.qtd}</TableCell>
                                )}

                                {dadosTabela.lstMenor30Km && (
                                    <TableCell>{dadosTabela.lstMenor30Km[index]?.qtd}</TableCell>
                                )}

                                {dadosTabela.lstMenor30Km && (
                                    <TableCell>
                                        {(dadosTabela.lstMenor30Km[index]?.qtd / dadosTabela.lstMenor30Km[index]?.qtd * 100).toFixed(2)}%
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                   

                </Table>
            </TableContainer>
            <div id='rederizarDialog' />
        </Container>
    );
}

export default Resultados;