import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    height: 520,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: 3
};

const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #000',
    paddingBottom: '8px'
};

const footerStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    display: 'flex',
    gap: '8px',
};

export default function FilterModal(props) {
    const { open, setOpen, onConfirm, dados } = props;
    const [ufDisponivel, setUfDisponivel] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState("");
    const [selectedUfs, setSelectedUfs] = React.useState([]);

    React.useEffect(() => {
        setUfDisponivel(dados)
    }, [])

    const handleClose = () => setOpen(false);

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm(selectedUfs);
        }
        setOpen(false);
    };
    const handleChangeSearch = (event) => {
        setSearchTerm(event.target.value);
    }

    const filteredUfs = ufDisponivel.filter((uf) =>
        uf.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedUfs(ufDisponivel);

        } else {
            setSelectedUfs([]);
        }
    };

    const handleSelectUf = (event) => {
        const { value } = event.target;
        if (selectedUfs.includes(value)) {
            setSelectedUfs(selectedUfs.filter((uf) => uf !== value));
        } else {
            setSelectedUfs([...selectedUfs, value]);
        }
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={headerStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Filtro
                        </Typography>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        <Grid container spacing={2} mt={0}>
                            <Grid item xs={12}>
                                <Grid>
                                    <Grid>
                                        <TextField
                                            onChange={handleChangeSearch}
                                            value={searchTerm}
                                            id="outlined-basic"
                                            label="Buscar UF"
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{ style: { textAlign: 'left' } }}
                                        />
                                    </Grid>
                                </Grid>
                                <Box
                                    sx={{ marginTop: 2, padding: 2, height: 240, overflowY: "auto", border: 1, borderColor: 'grey.300', borderRadius: 2 }}
                                >
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedUfs.length === ufDisponivel.length}
                                                    onChange={handleSelectAll}
                                                />
                                            }
                                            label="Todos"
                                        />
                                        {filteredUfs.map((uf) => (
                                            <FormControlLabel
                                                sx={{ marginLeft: 2 }}
                                                key={uf}
                                                control={
                                                    <Checkbox
                                                        checked={selectedUfs.includes(uf)}
                                                        onChange={handleSelectUf}
                                                        value={uf}
                                                    />
                                                }
                                                label={uf.toUpperCase()}
                                            />
                                        ))}
                                    </FormGroup>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                    <Box sx={footerStyle}>
                        <Button variant="contained" color="primary" onClick={handleConfirm}>
                            Confirmar
                        </Button>
                        <Button variant="contained" color="error" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
