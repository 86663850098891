import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { IconButton } from '@mui/material';
import Service from '../../services/Service';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

// function createData(
//     name: string,
//     calories: number,
//     fat: number,
//     carbs: number,
//     protein: number,
// ) {
//     return { name, calories, fat, carbs, protein };
// }

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

export default function GenericTable({ click, dado }) {
   
    const handleOpen = (item) => {
        // setOpen(true);
        click(item)
    };
    function formataData(dt){
        if( !dt) return '';
        return new Date(dt).toLocaleString('pt-BR', { timezone: 'UTC' })
    }
    function formataUF( filtro ){
        if( !filtro)return '';
        if( filtro.UF ){
            let txt = '';
            for(let x in filtro.UF){
                txt += filtro.UF[x]+", ";
            }
            txt = txt.substring( 0, txt.length-2);
            return txt;
        }
    }
    function formataMunicipio( bloco ){
        const filtro = bloco.filtro;
        if( !filtro)return '';
        if( filtro.CIDADE ){
            let txt = '';
            for(let x in filtro.CIDADE){
                txt += bloco[filtro.CIDADE[x]]+", ";
            }
            txt = txt.substring( 0, txt.length-2);
            return txt;
        }
        return '';

    }
    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead >
                    <TableRow>
                        <StyledTableCell style={{backgroundColor: "#044785"}}>UF</StyledTableCell>
                        <StyledTableCell style={{backgroundColor: "#044785"}} align="right">Cidade</StyledTableCell>
                        <StyledTableCell style={{backgroundColor: "#044785"}} align="right">Status</StyledTableCell>
                        <StyledTableCell style={{backgroundColor: "#044785"}} align="right">Data e Hora</StyledTableCell>
                        <StyledTableCell style={{backgroundColor: "#044785"}} align="right">Detalhar</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dado && dado.map((row, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                                { formataUF(row.filtro) }
                            </StyledTableCell>
                            <StyledTableCell align="right">{ formataMunicipio(row) }</StyledTableCell>
                            <StyledTableCell align="right">{row.estado}</StyledTableCell>
                            <StyledTableCell align="right">{ formataData(row.dtCriacao) }</StyledTableCell>
                            <StyledTableCell align="right">
                                <IconButton aria-label="Example" onClick={() => handleOpen(row) } >
                                    <RemoveRedEyeIcon />
                                </IconButton>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}