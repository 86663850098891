import './Setings.css'
import React from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SendIcon from '@mui/icons-material/Send';
import { Container, Stack, Button, Typography, TextField, Slider, Grid } from "@mui/material";
import Service from "../services/Service";
import AlertDialog from './AlertDialog';

function Settings() {
    const [checkedCalculo, setCheckedCalculo] = React.useState(true);
    const [checkedAPI, setCheckedAPI] = React.useState(false);

    const [numeroProcessos, setNumeroProcessos] = React.useState(0);
    const [numeroPods, setNumeroPods] = React.useState(0);
    const [distancia, setDistancia] = React.useState(30);

    const [disableConfirm, setDisableConfirm] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const [carregarSetrings, setCarregarSetrings] = React.useState(true);

    React.useEffect(() => {
        if (carregarSetrings) {
            buscaSettings();
        }
        
    }, [carregarSetrings]);

    function buscaSettings() {
        Service.GET('/api/settings/find')
          .then(r => {
            if(r != null){
            setCheckedAPI(r.isAPI);
            setCheckedCalculo(r.isCalculo);
            setNumeroPods(r.numeroPods);
            setNumeroProcessos(r.numeroProcessos);
            setDistancia(r.distancia);
            setCarregarSetrings(false);
            }else{

            }
          });

      }

    const handleSubmit = (event) => {
        event.preventDefault()
        setDisableConfirm(true);
        console.log("---")
        const dado = {
            numeroProcessos: numeroProcessos,
            numeroPods: numeroPods,
            distancia: distancia,
            isAPI: checkedAPI,
            isCalculo: checkedCalculo
        }
        Service.POST('/api/settings/save', dado)
            .then(r => {
                console.log(r);
                setDisableConfirm(false);
                setOpen(true);
                //buscaSettings();
            });
    }

    const handleSliderChange = (event, newValue) => {
        setDistancia(newValue);
    };

    const handleNumeroProcessos=(e)=>{
        setNumeroProcessos(e.target.value)
    }

    const handleNumeroPods=(e)=>{
        setNumeroPods(e.target.value)
    }

    function valuetext(value) {
        return `${value} km`;
    }

    const handleChange=(e)=>{
        console.log(e.target.name );
        if( e.target.name === 'API'){
            setCheckedAPI( e.target.checked);
        }else{
            setCheckedCalculo( e.target.checked );
        }
    }

    return (
        <Container maxWidth="sm" style={{ marginBottom: '40px', marginTop: '20px' }}>
            <Stack spacing={1} className='stack'>
                <Typography variant="h3" gutterBottom style={{ textAlign: 'center', marginTop: '20px'}} className='margin10px'>
                    Configuração
                </Typography>
                <form onSubmit={handleSubmit}>
                    <div className='margin10px'>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                <h4>Delimitar distância</h4>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={4}>
                                <Slider
                                    value={distancia}
                                    min={0}
                                    max={100}
                                    onChange={handleSliderChange}
                                    getAriaValueText={valuetext}
                                    valueLabelFormat={valuetext}
                                    aria-labelledby="input-slider"
                                    valueLabelDisplay="auto"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <span className='km'>{valuetext(distancia)} </span>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='margin10px'>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Checkbox name="CALC" onChange={handleChange} checked={checkedCalculo}/>
                                    } label="Fazer Calculo Ponto a Ponto" />
                                    <FormControlLabel control={
                                        <Checkbox name="API" onChange={handleChange} checked={checkedAPI}/>
                                    } label="Buscar rota via API-GOOGLE" />

                                </FormGroup>
                            </Grid>
                        </Grid>
                    </div>

                    <div>
                        <Button variant="contained" type='submit' endIcon={<SendIcon />} disabled={disableConfirm}>
                            Confirmar
                        </Button>
                    </div>
                </form>
            </Stack>
            <AlertDialog open={open} setOpen={setOpen} children={"Configuração atualizada com sucesso!!"} title={"INEP"} />
        </Container>
    )
}

export default Settings;